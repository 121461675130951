import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  DirectionsRenderer,
} from "@react-google-maps/api";
import { useEffect, useState } from "react";

const center = { lat: 37.8780175, lng: -98.6532812 };

function Map({mapPlotData}) {
  // const { isLoaded } = useJsApiLoader({
  //   googleMapsApiKey: '',
  //   libraries: ['places'],
  // })

  const containerStyle = {
    width: "100%",
    height: "calc(100vh - 65px)",
  };

  const [map, setMap] = useState(/** @type google.maps.Map */ (null));
  const [directionsResponse, setDirectionsResponse] = useState(null);
  // useEffect(() => {
  //   const originRef = localStorage.getItem("originRef");
  //   const destinationRef = localStorage.getItem("destinationRef");
  //   console.log("========================================");
  // }, []);
  
  if(mapPlotData){
    const calculateRoute = async () => {
      // eslint-disable-next-line no-undef
      const directionsService = new google.maps.DirectionsService();
      const results = await directionsService.route({
        origin: mapPlotData.originRef,
        destination: mapPlotData.destinationRef,
        // eslint-disable-next-line no-undef
        travelMode: google.maps.TravelMode.DRIVING,
      });
      setDirectionsResponse(results);
    };
    calculateRoute().catch(console.error);
  }

  return (
    <>
      {/* Google Map */}
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={4}
        options={{
          zoomControl: true,
          streetViewControl: true,
          mapTypeControl: true,
          fullscreenControl: true,
        }}
        onLoad={(map) => setMap(map)}
      >
        {/* <Marker position={center} /> */}
        {directionsResponse && (
          <DirectionsRenderer directions={directionsResponse} />
        )}
      </GoogleMap>
    </>
  );
}

export default Map;
