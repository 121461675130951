import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import VehicleInput from "./VehicleInput";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
// import DatePicker from "react-modern-calendar-datepicker";

// Month Year Picker
import "date-fns";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";

const AddPayment = ({ setConfirmStatus }) => {
  const [show, setShow] = useState(false);
  const [payment, setPayment] = useState({
    cardNumber: "",
    expireDate: "",
    cvc: "",
    holderName: "",
  });
  const [error, setError] =useState({
    cardNumber: "",
    expireDate: "",
    cvc: "",
    holderName: "",
  });

  const [isVisible, setIsVisible] = useState(true);
  let card = JSON.parse(localStorage.getItem('payment_response_data'));

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  let cardNumberValidation = new RegExp('^[0-9]{16,19}$');
  let cvcValidation = new RegExp('^[0-9]{3,4}?$');

  function paymentData() {
    // console.log(cardNumberValidation.test(payment.cardNumber));

    if(payment.cardNumber === "")
    {
      setError({cardNumber: "Card Number is empty"});
    }
    else if(!cardNumberValidation.test(payment.cardNumber))
    {
      setError({cardNumber: "Please enter a valid credit card number."});
    }
    else if(payment.expireDate === "")
    {
      setError({expireDate: "Invalide Date format."});
    }
    else if(payment.cvc === "")
    {
      setError({cvc: "CVC is required"});
    }
    else if(!cvcValidation.test(payment.cvc))
    {
      setError({cvc: "CVC must be of 3-4 digits"});
    }
    else if(payment.holderName === "")
    {
      setError({holderName: "Holder Name is required"});
    }
    else
    {
      localStorage.setItem("payment_response_data", JSON.stringify(payment));
      localStorage.setItem('payment_method', 'Credit card (FULL)');
      setConfirmStatus(true);
      setIsVisible(false);
      handleClose();
    }
  }

  // Custom Date
  // const [selectedDay, setSelectedDay] = useState(null);
  // const current = new Date();
  // const minimumDate = {
  //     year: current.getFullYear(),
  //     month: (current.getMonth() + 1),
  //     day: current.getDate() + 1
  // };

  // const renderCustomInput = ({ ref }) => (
  //   <div className="input-group mb-3">
  //     <div className="input-group-prepend">
  //       <span className="input-group-text">
  //         <i className="fa fa-calendar-check"></i>
  //       </span>
  //     </div>
  //     <input
  //     readOnly
  //     ref={ref} // necessary
  //     placeholder="Expire Date"
  //     value={selectedDay ? `${selectedDay.month}/${selectedDay.year}` : ''}
  //     className="form-control"
      
  //     style={{
  //       height: '50px'
  //     }}
  //     />
  //   </div>
  // )

  // const getSelectedDate = (e) => {
  //   setSelectedDay(e);
  //   setPayment({ ...payment, expireDate: e.month+'/'+e.year});
  // }

  // Month Year Picker
  const current = new Date();
  const [selectedDate, setSelectedDate] = React.useState(null);

  const handleDateChange = (date,val) => {
    if(!date||isNaN(date))
    {
      setPayment({ ...payment, expireDate: ""});
    }
    else
    {
      setSelectedDate(date);
      setPayment({ ...payment, expireDate: val});
    }
  };
  

  return (
    <div className="mb-2"> 
          {isVisible ? 
            <h6 className="add-card-link" onClick={handleShow}>
             <i className="fa fa-plus"></i> Add Card
            </h6> : <h6 className="show-card-num" style={{height:"1px"}}>Your Card: <span>xxxxxxxxxxxx{card.cardNumber.substr(-4)}</span></h6> 
          }

          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              {/* <Modal.Title>Card Information</Modal.Title> */}
            </Modal.Header>
            <Modal.Body className="card-info-modal">
              <div className="row mb-3">
                <div className="col-md-12">
                  <div className="d-flex justify-content-between align-items-center add-payment">
                    <VehicleInput
                      icon="fa-credit-card"
                      placeholder={"Card Number"}
                      type={"payment"}
                      pay={payment}
                      setPayment={setPayment}
                      paymentType="number"
                    />
                  </div>
                  <p className="text-danger">{error.cardNumber}</p>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-6 ">
                  <div className="d-flex justify-content-between align-items-center add-payment ">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid>
                          <KeyboardDatePicker
                            disableToolbar
                            disablePast
                            // variant="inline"
                            inputVariant="outlined"
                            format="MM/yyyy"
                            views={["year", "month"]}
                            margin="normal"
                            id="date-picker-inline"
                            value={selectedDate}
                            onChange={(value, e) => handleDateChange(value, e)}
                            InputAdornmentProps={{ position: "start" }}
                            placeholder="01/2025"
                            className="card-expiry-date"
                            // style={{padding: "unset", margin: "unset"}}
                          />
                        </Grid>
                      </MuiPickersUtilsProvider>
                  <p className="text-danger">{error.expireDate}</p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="d-flex justify-content-between align-items-center add-payment">
                    <VehicleInput
                      icon="fa-qrcode"
                      placeholder={"CVC"}
                      type={"payment"}
                      pay={payment}
                      setPayment={setPayment}
                      paymentType="cvc"
                    />
                  </div>
                  <p className="text-danger">{error.cvc}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="d-flex justify-content-between align-items-center add-payment">
                    <VehicleInput
                      icon="fa-user"
                      placeholder={"Holder Name"}
                      type={"payment"}
                      pay={payment}
                      setPayment={setPayment}
                      paymentType="holder"
                    />
                  </div>
                  <p className="text-danger">{error.holderName}</p>
                </div>
              </div>
              {/* <Link to="/" className="btn-main mt-3" onClick={handleClose}>
                Add Card
              </Link> */}
              <button className="btn-main mt-3" onClick={paymentData}>
                Add Card
              </button>
            </Modal.Body>
          </Modal> 
    </div>
  );
};

export default AddPayment;
