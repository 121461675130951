import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Autocomplete } from '@react-google-maps/api'

const InputField = ({ go, dot, icon, TogglerHandler, setMapPlotData }) => {
  // Google autocomplete
  const [autocomplete, setAutocomplete] = useState(null);
  const originRef = useRef();

  function onLoad(param) {
    setAutocomplete(param);
  }

  function handleAutoComplete() {
    const getLat = autocomplete.getPlace().geometry.location.lat();
    const getLng = autocomplete.getPlace().geometry.location.lng();
    const getName = autocomplete.getPlace().name;
    let data;
    if(go == "Pickup")
    {
      data={
        originRef:originRef.current.value,
        destinationRef:'Boston Logan International Airport (BOS), Boston, MA, USA',
      }
      localStorage.setItem('startLat',getLat);
      localStorage.setItem('startLng',getLng);
      localStorage.setItem('start',getName);
      localStorage.setItem('originRef', originRef.current.value);
    }
    else
    {
      data={
        originRef:'Boston Logan International Airport (BOS), Boston, MA, USA',
        destinationRef:originRef.current.value,
      }
      localStorage.setItem('endLat',getLat);
      localStorage.setItem('endLng',getLng);
      localStorage.setItem('end',getName);
      localStorage.setItem('destinationRef', originRef.current.value);
    }
    setMapPlotData(data)
  }

  return (
    <>
      <div className="input-group">
        <div className="input-group-prepend ">
          <span className="input-group-text ">
            <span className={dot}></span>
          </span>
        </div>
        
        <Autocomplete onLoad={onLoad} onPlaceChanged={handleAutoComplete} options={{componentRestrictions: { country: ['us'] },}}>
            <input type='text' className="form-control br-0 inputstyle" placeholder={go} ref={originRef} />
        </Autocomplete>
        <div className="input-group-append ">
          <span className="input-group-text text-2">
            <Link to="">
              <span className="material-icons">
                <i
                  className={icon}
                  onClick={() => {
                    TogglerHandler("hgfdhfjkdfh");
                  }}
                ></i>
              </span>
            </Link>
          </span>
        </div>
      </div>
    </>
  );
};

export default InputField;
