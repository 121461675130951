import React, { useEffect, useState } from "react";
import AddPayment from "./AddPayment";
import ModalConfirmBtn from "../VehicelExtraInfoComp.js/ModalConfirmBtn";
import PaypalCheckoutButton from "../PaypalComponent/Checkout";

const Payment = ({ setPaymentType }) => {
  const [show, setShow] = useState(true);
  const [confirmStatus, setConfirmStatus] = useState(false);
  const styleVal = {
    "font-size": "16px",
    "font-weight": "700",
    "margin-right": "20px",
    cursor: "pointer",
  };
  function handleToggle(e) {
    if (e.target.value === "paypal") {
      setShow(false);
      setPaymentType(true);
      localStorage.setItem("payment_method","Paypal")
    } else {
      setShow(true);
      setPaymentType(false);
      localStorage.setItem("payment_method"," Credit card (FULL)")
    }
  }

  return (
    <>
      <div className="col-md-12 mt-3" style={{position:"relative"}}>
        <label style={styleVal}>
          <input
            type="radio"
            value="card"
            name="payment"
            defaultChecked
            onClick={(e) => {
              handleToggle(e);
            }}
          />
         Pay With Card
        </label>
        <label style={styleVal}>
          <input
            type="radio"
            value="paypal"
            name="payment"
            onClick={(e) => {
              handleToggle(e);
            }}
          />
          Pay With Paypal

        </label>
        {show ? (
          <div className="mt-1">
            <AddPayment setConfirmStatus={setConfirmStatus} />
            <ModalConfirmBtn confirmStatus={confirmStatus} />
          </div>
        ) : (
          <div className="mt-3">
            <ModalConfirmBtn confirmStatus={confirmStatus} />
            {/* <PaypalCheckoutButton startPaypal={setPaymentType} /> */}
          </div>
        )} 
        </div>
    </>
  );
};

export default Payment;
