import React, { useState } from "react";
import Backbtn from "../Components/VehicleSelectionComp/Backbtn";
import Logo from "../Components/Logo";
import Map from "../Components/Map";
import HeaderMenu from "../Components/HeaderMenu";
// import VehicleDesc from "../Components/VehicelExtraInfoComp.js/VehicleDesc";
import JourneyDesc from "../Components/VehicelExtraInfoComp.js/JourneyDesc";
import Capacity from "../Components/VehicelExtraInfoComp.js/Capacity";
import Add from "../Components/VehicelExtraInfoComp.js/Add";
import BasicInformation from "../Components/VehicelExtraInfoComp.js/BasicInformation";
import FlightInformation from "../Components/VehicelExtraInfoComp.js/FlightInformation";
import Payment from "../Components/VehicelExtraInfoComp.js/Payment";
import LeadPassanger from "../Components/VehicelExtraInfoComp.js/LeadPassanger";
import NoteToDriver from "../Components/VehicelExtraInfoComp.js/NoteToDriver";

const VehicleExtraInfo = () => {
  const [paymentType, setPaymentType] = useState(false);
  return (
    <>
      <header>
        <Logo />
        <HeaderMenu />
      </header>
      <div className="container-fluid dim-bg payment-page">
        <div className="row">
          <div className="col-md-6">
            <JourneyDesc paymentType={paymentType} backTo='/vehicleselection' />
          </div>
          <div className="col-md-6">
            <div className="floating-sidebar" style={{ zIndex: 1 }}>
              <div className="extrawrapper hav-height">
                <div className="row">
                <BasicInformation /> 
                <FlightInformation /> 
                <NoteToDriver />
                <Payment setPaymentType={setPaymentType} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VehicleExtraInfo;
