import React, { useEffect, useState } from "react";
import PaymentCheckout from "../PaypalComponent/Checkout";
import { Link } from "react-router-dom";
const journeyData = [
  {
    icon: "far fa-calendar-alt",
    heading: "Journey Information",
    para1: "214 Airport Rd Melbourne",
    para2: "Sydney Opera",
    para3: "",
    para4: "",
  },
  {
    icon: "fas fa-road",
    heading: "Coverage",
    para1: "",
    para2: "",
    para3: "12 Km(s)",
    para4: "2hrs",
  },
  {
    icon: "fas fa-car",
    heading: "Choose Vehicles",
    para1: "",
    para2: "",
    para3: "",
    para4: "",
    para5: "Standard Class SUV",
    para6: "../images/image1.png",
    para7: "7",
    para8: "2",
    para9: "6",
  },
];
const JourneyDesc = ({ confirmStatus, paymentType, backTo }) => {
  const [journeyData, setJourneyData] = useState([]);
  const [totalFare, setTotalFare] = useState(null);
  const [startPaypal, setStartPaypal] = useState(false);
  useEffect(() => {
    let quoteData = JSON.parse(localStorage.getItem("data"));
    let data = JSON.parse(localStorage.getItem("vehicleSelection"));
    console.log(quoteData,data,'-----------')
    setTotalFare(data?parseFloat(data.selected_fleet.fare_details.total_fare).toFixed(2):0);

    setJourneyData([
      {
        show:true,
        icon: "far fa-map",
        heading: "Journey Information",
        para1: localStorage.getItem("originRef"),
        para2: localStorage.getItem("destinationRef"),
        para3: "",
        para4: "",
        para32:"TO"
      },
      {
        show:quoteData?true:false,
        icon: "fas fa-road",
        heading: "Coverage",
        para1: "",
        para2: "",
        para3: quoteData?quoteData.maps.distance:0,
        para4: quoteData?quoteData.maps.duration:0,
      },
      {
        show:quoteData?true:false,
        icon: "fas fa-calendar-alt",
        heading: "Pickup Date Time",
        para1: quoteData?quoteData.quote_info.pick_date:'',
        para2: quoteData?quoteData.quote_info.pick_time:'',
        para3: quoteData?'Passenger: '+quoteData.quote_info.passengers:'',
        para4: quoteData?'Luggage: '+quoteData.quote_info.luggage + ((quoteData.quote_info.baby_seats>0)?', ['+quoteData.quote_info.baby_seat_details+']':'') :'',
      },
      {
        show:data?true:false,
        icon: "fas fa-car",
        heading: "Choose Vehicles",
        para1: "",
        para2: "",
        para3: "",
        para4: "",
        para5: data?data.selected_fleet.name:'',
        para6: data?data.selected_fleet.info.img_link:'',
        para7: "7",
        para8: "2",
        para9: "6",
      },
    ]);
  }, []);

  const handlePayment = () => {
    setStartPaypal(true);
  };

  return (
    <>
      <div className="journey-info">
        <Link className="back-btn" to={backTo}>
          <i className="fa fa-angle-left"></i> &nbsp; Back
        </Link>
        <h1>
          Summary <span>{totalFare ? "$" + totalFare : ""}</span>
        </h1>
        {journeyData.map((data, index) => {
          return (
            data.show?
            <div className="journey-info-content" key={index}>
              <i className={data.icon}></i>
              <div>
                <h4>{data.heading}</h4>
                <p>{data.para1}</p> 
                <strong>{data.para32}</strong>
                <p>{data.para2}</p>
                {data.para3?<p>{data.para3}, {data.para4}</p>:''}
                <p>{data.para5} <img className="fleet-img" style={{float:"right"}} src={data.para6} /></p>
                
              </div>
            </div>:<></>
          );
        })}
        {/* {paymentType ? <PaymentCheckout startPaypal={startPaypal} /> : ""} */}
      </div>
    </>
  );
};

export default JourneyDesc;
