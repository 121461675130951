import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { postRequest } from "../../Helpers/APIHelper";
import { Link, useNavigate } from "react-router-dom";
import { config } from '../../Helpers/Constants'

function PaypalCheckoutButton({ startPaypal }) {
  const navigate = useNavigate();
  const [booked, setBooked] = useState(false);

  // ################ Validation ################

  const [showSuccess, setShowSuccess] = useState(false);
  const handleSuccessClose = () => setShowSuccess(false);

  const [showError, setShowError] = useState(false);
  const handleErrorClose = () => setShowError(false);

  // Form Validation
  const [showBasicInfo, setShowBasicInfo] = useState(false);
  const handleBasicInfoClose = () => setShowBasicInfo(false);

  const [showFlightInfo, setShowFlightInfo] = useState(false);
  const handleFlightInfoClose = () => setShowFlightInfo(false);

  const [showInfo, setShowInfo] = useState(false);
  const handleInfoClose = () => setShowInfo(false);

  //   Confirm
  const [showErrorModel, setShowErrorModel] = useState(false);
  const handleErrorModelClose = () => setShowErrorModel(false);
  const handleModelErrorShow = () => setShowErrorModel(true);
  const [customError, setCustomError] = useState('');
  const [customMessage, setCustomMessage] = useState('');
  const [btnDisable, setBtnDisable] = useState(false);

  const reloadLocation = () => {
    window.location.reload();
  }
  //Live
  var clientID = "AdysC9fE9_HNQvfLjYqX8HvFczxAkBPgPtMxQxfn20qqnoD9MfWoYIPK-_Bprq9LUpBvJ45P82d_tUu1";
  if (process.env.NODE_ENV === 'development') {
    //Sandbox Developer Test
    clientID = "AR4m6p_F8tKYdWKORCSOEwe_pPEBYOGxhG8C8lPfU7mGx-A_h-gWzCFZZnX1G6iKpVGAKExDUee5L2WY";
    //Sandbox Client Test
    //  clientID = "AQUp1nV03RqZjFECWd20CzVecc0HwUb00z5VZUCk2CZuiafPdHPVgwcigQd_LbARea6GBu2Fw4AVgja0";
  }




  return (
    <>
      {btnDisable ? <div className="loading"></div> : ''}
      <PayPalScriptProvider
        options={{ "client-id": clientID, "enable-funding": "card" }}
      >
        <PayPalButtons
          style={{ color: "blue", layout: "horizontal", tagline: false, shape: "pill" }}
          onClick={(data, actions) => {
            if (booked) {
              setShowError(true);
              return actions.reject();
            } else {
              const name = localStorage.getItem("username");
              const email = localStorage.getItem("email");
              const phone = localStorage.getItem("phone");
              const flight_name = localStorage.getItem("flight_name");
              const flight_number = localStorage.getItem("flight_number");
              if (localStorage.getItem('fromTo') === 'from_airport') {
                if (
                  (!name || !email || !phone) &&
                  (!flight_name || !flight_number)
                ) {
                  setShowInfo(true);
                  return actions.reject();
                } else if (!name || !email || !phone) {
                  setShowBasicInfo(true);
                  return actions.reject();
                } else if (!flight_name || !flight_number) {
                  setShowFlightInfo(true);
                  return actions.reject();
                } else {
                  return actions.resolve();
                }
              }
              else {
                if (!name || !email || !phone) {
                  setShowBasicInfo(true);
                  return actions.reject();
                }
                else {
                  return actions.resolve();
                }
              }
            }
          }}
          createOrder={(data, actions) => {
            let quoteData = JSON.parse(localStorage.getItem("data"));
            let vehicleSelection = JSON.parse(localStorage.getItem("vehicleSelection"));
            return actions.order.create({
              purchase_units: [
                {
                  description: 'FROM:' + quoteData.quote_info.start + ', TO:' + quoteData.quote_info.end + ', ' + quoteData.quote_info.pick_date + ' ' + quoteData.quote_info.pick_time + ', ' + vehicleSelection.selected_fleet.name,
                  amount: {
                    value:
                      vehicleSelection.selected_fleet.fare_details.total_fare,
                  },
                },
              ],
            });
          }}
          onApprove={async (data, action) => {
            // const order = action.order.capture();
            // if (order) {
            setBooked(true);
            // To be maintain later
            const vehicleData = JSON.parse(localStorage.getItem("vehicleSelection"));
            const booking = {
              name: localStorage.getItem("username"),
              phone: localStorage.getItem("phone"),
              email: localStorage.getItem("email"),
              flight_number: localStorage.getItem("flight_number"),
              flight_name: localStorage.getItem("flight_name"),
              payment_method: "Paypal",
              quote_info_record_id: vehicleData.quote_info_record_id,
              maps_record_id: vehicleData.maps_record_id,
              selected_vehicle_record_id: vehicleData.selected_vehicle_record_id,
              // payment_response_data: order,
              order_data: data,
            };
            setBtnDisable(true);
            postRequest("quote/confirm", booking).then((res) => {
              if (res.status === 1) {
                // setShow(true);
                let finishBooking = {
                  quote_info_record_id: res.data.quote_info_record_id,
                  selected_vehicle_record_id:
                    res.data.selected_vehicle_record_id,
                  booking_info_record_id: res.data.booking_info_record_id,
                };
                postRequest("quote/finish", finishBooking)
                  .then((res) => {
                    // console.log(res);
                    setBtnDisable(false);
                    // if (res.status === 1) {
                    // setShowSuccess(true);
                    // setCustomMessage(res.message);
                    localStorage.clear();
                    const domainList = {
                      "booking.gobostonloganairport.com": { prefix: "GOBOS", domain_id: 1 },
                      "booking.bostonairportexpresscar.com": { prefix: "BAEC", domain_id: 2 },
                      "booking.airporttaxima.com": { prefix: "ATM", domain_id: 3 },
                      "booking.bostonairportcheapcar.com": { prefix: "BACC", domain_id: 4 },
                      "booking.loganairportcar.com": { prefix: "LAC", domain_id: 5 },
                      "booking.bostonloganairportcab.com": { prefix: "BLAC", domain_id: 6 },
                      "booking.natickairporttaxi.com": { prefix: "NAT", domain_id: 7 },
                      "booking.concordairporttaxi.com": { prefix: "CON", domain_id: 8 },
                      "booking.airportcarlexington.com": { prefix: "LEX", domain_id: 9 },
                      "booking.walthamairporttaxi.com": { prefix: "WAL", domain_id: 10 },
                      "booking.airporttaxiarlington.com": { prefix: "ARL", domain_id: 11 },
                      "booking.airporttaxicambridgema.com": { prefix: "CAM", domain_id: 12 },
                      "booking.airporttaxiandover.com": { prefix: "ADOV", domain_id: 13 },
                      "booking.newtonairporttaxima.com": { prefix: "NEW", domain_id: 14 },
                      "booking.actonairporttaxi.com": { prefix: "AAT", domain_id: 15 },
                      "booking.hudsonairporttaxi.com": { prefix: "HUD", domain_id: 16 },
                      "booking.bosairportlimos.com": { prefix: "BLIMO", domain_id: 17 },
                      "booking.westonairporttaxima.com": { prefix: "WEST", domain_id: 18 },
                      "booking.wilmingtonairporttaxis.com": { prefix: "WAT", domain_id: 19 },
                      "booking.bostonairportcarma.com": { prefix: "BACMA", domain_id: 20 },
                      "booking.bostonairporttaxis.com": { prefix: "BAT", domain_id: 21 },
                      "booking.gologanairport.com": { prefix: "GLA", domain_id: 22 },
                      "booking.gobostonairport.com": { prefix: "GBA", domain_id: 23 },
                      "booking.worcesterairporttaxi.com": { prefix: "WOC", domain_id: 24 },
                      "booking.loganexpresscar.com": { prefix: "LEC", domain_id: 25 },
                    };
                    const domainName = window.location.hostname;
                    localStorage.setItem("domain_id", 22);
                    if (domainList[domainName]??false) {
                      localStorage.setItem("domain_id", domainList[domainName].domain_id);
                    }
                    localStorage.setItem('fromTo', "from_airport");
                    localStorage.setItem('finishMsg', res.message);
                    localStorage.setItem('refId', (res.data ? res.data.booking_ref_id : ''));
                    localStorage.setItem('startLat', 42.3656132);
                    localStorage.setItem('startLng', -71.0095602);
                    localStorage.setItem('start', "Boston Logan International Airport (BOS), Boston, MA, USA");
                    localStorage.setItem('originRef', "Boston Logan International Airport (BOS), Boston, MA, USA");
                    navigate('/thank-you');
                    // }
                    // else {
                    //   setShowErrorModel(true);
                    //   setCustomError(res.message);
                    // }
                  })
              } else {
                setBtnDisable(false);
              }
            });
            // }
          }}
          onCancel={() => { }}
          onError={(err) => {
            setShowError(true);
          }}
        />
      </PayPalScriptProvider>

      {/* Transaction Success Alert */}
      <Modal show={showSuccess} onHide={handleSuccessClose} backdrop="static">
        <Modal.Body className="text-center">
          <Modal.Title className="custom-modal-title">Confirmed Success!</Modal.Title>
          <p className="text-center fs-14">
            {/* Your transaction for the booking has been success. */}
            {customMessage}
          </p>
          <Link to="/" className="booking-btn-conform">
            OK
          </Link>
        </Modal.Body>
      </Modal>

      {/* Transaction Error Alert */}
      <Modal show={showError} onHide={handleErrorClose} backdrop="static">
        <Modal.Header ></Modal.Header>
        <Modal.Body className="text-center">
          <Modal.Title className="custom-modal-title">SORRY!</Modal.Title>
          <p className="text-center fs-14">
            Something error during the payment. Please Try Again!
          </p>
          <button className="booking-btn-conform" onClick={reloadLocation}>
            OK
          </button>
        </Modal.Body>
      </Modal>

      {/* Form Validation */}
      {/* Add Basic Information Alert */}
      <Modal show={showBasicInfo} onHide={handleBasicInfoClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="text-center">
          <Modal.Title className="custom-modal-title">
            Basic Information Empty!
          </Modal.Title>
          <p className="text-center fs-14">
            Please fill up all the input field.
          </p>
          <button
            className="booking-btn-conform"
            onClick={handleBasicInfoClose}
          >
            OK
          </button>
        </Modal.Body>
      </Modal>

      {/* Add Flight Information Alert */}
      <Modal show={showFlightInfo} onHide={handleFlightInfoClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="text-center">
          <Modal.Title className="custom-modal-title">
            Flight Information Empty!
          </Modal.Title>
          <p className="text-center fs-14">
            Please fill up all the input field.
          </p>
          <button
            className="booking-btn-conform"
            onClick={handleFlightInfoClose}
          >
            OK
          </button>
        </Modal.Body>
      </Modal>

      {/* Add Flight Information Alert */}
      <Modal show={showInfo} onHide={handleInfoClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="text-center">
          <Modal.Title className="custom-modal-title">
            Information Empty!
          </Modal.Title>
          <p className="text-center fs-14">
            Please fill up Basic Information and Flight Information.
          </p>
          <button className="booking-btn-conform" onClick={handleInfoClose}>
            OK
          </button>
        </Modal.Body>
      </Modal>

      {/* Error Model */}
      <Modal show={showErrorModel} onHide={handleErrorModelClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="text-center">
          <Modal.Title className="custom-modal-title">
            SORRY!
          </Modal.Title>
          <p className="text-center fs-14">
            {customError}
          </p>
          <button className="booking-btn-conform" onClick={handleErrorModelClose}>
            OK
          </button>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default PaypalCheckoutButton;
