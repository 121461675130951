import HomeForm from "../Components/HomePageComp/HomeForm";
import Logo from "../Components/Logo";
import Map from "../Components/Map";
import HeaderMenu from "../Components/HeaderMenu";
import { useState } from "react";

const Home = () => {
  const [mapPlotData, setMapPlotData] = useState([]);
  return (
    <>
      <header>
        <Logo />
        <HeaderMenu />
      </header>
      <div className="container-fluid dim-bg main-quote-page">
        <div className="row ">
          <div className="col-md-5">
            <div className="floating-sidebar " style={{ zIndex: 1 }}>
              <HomeForm setMapPlotData={setMapPlotData}/>
            </div>
          </div>
          <div className="col-md-7">
            <div className="page-wrapper">
              <Map mapPlotData={mapPlotData}/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
