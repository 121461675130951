import React, { useEffect, useState } from "react";

const SelectItem = ({ name, icon, type }) => {
 let options = [
    {
      label: "0",
      value: 0,
    },
    {
      label: "1",
      value: 1,
    },
    {
      label: "2",
      value: 2,
    },
    {
      label: "3",
      value: 3,
    },
    {
      label: "4",
      value: 4,
    },
    {
      label: "5",
      value: 5,
    },
    {
      label: "6",
      value: 6,
    },
    {
      label: "7",
      value: 7,
    },
  ];
  if(type=='passengers'){
   options = [
    {
      label: "Passengers",
      value: 1,
    },
    {
      label: "1",
      value: 1,
    },
    {
      label: "2",
      value: 2,
    },
    {
      label: "3",
      value: 3,
    },
    {
      label: "4",
      value: 4,
    },
    {
      label: "5",
      value: 5,
    },
    {
      label: "6",
      value: 6,
    },
    {
      label: "7",
      value: 7,
    },
  ];
  }else if(type=='luggage'){
    options = [
      {
        label: "Luggage",
        value: 0,
      },
      {
        label: "0",
        value: 0,
      },
      {
        label: "1",
        value: 1,
      },
      {
        label: "2",
        value: 2,
      },
      {
        label: "3",
        value: 3,
      },
      {
        label: "4",
        value: 4,
      },
      {
        label: "5",
        value: 5,
      },
      {
        label: "6",
        value: 6,
      },
      {
        label: "7",
        value: 7,
      },
    ];
  }
  else if(type=='booster_seat'){
    options = [
      {
        label: "Boaster",
        value: 0,
      },
      {
        label: "0",
        value: 0,
      },
      {
        label: "1",
        value: 1,
      },
      {
        label: "2",
        value: 2,
      },
      {
        label: "3",
        value: 3,
      },
      
    ];
  }
  else if(type=='regular_seat'){
    options = [
      {
        label: "Regular",
        value: 0,
      },
      {
        label: "0",
        value: 0,
      },
      {
        label: "1",
        value: 1,
      },
      {
        label: "2",
        value: 2,
      },
      {
        label: "3",
        value: 3,
      },
      
    ];
  }
  else if(type=='infant_seat'){
    options = [
      {
        label: "Infant",
        value: 0,
      },
      {
        label: "0",
        value: 0,
      },
      {
        label: "1",
        value: 1,
      },
      {
        label: "2",
        value: 2,
      },
      {
        label: "3",
        value: 3,
      },
      
    ];
  }
  
  function handleOption(e) {
    localStorage.setItem(type,e.target.value);
  }

  return (
    <>
      <div className="select-align-div input-group">
        <div className="input-group-prepend ">
          <span className="input-group-text text-2">
            <i className={icon}></i>
          </span>
        </div>
        
          {/* <label for={name}>{name}</label> */}
          <select className="form-select " id={name} onChange={e => handleOption(e)}>
          {options.map((option) => (
            <option value={option.value} >{option.label}</option>
            ))}
          </select> 
      </div>
    </>
  );
};

export default SelectItem;
