import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; 

const HeaderMenu = () => {
  const [isActive, setActive] = useState("false");
  const handleToggle = () => {
    setActive(!isActive);
  };
  const navigate  = useNavigate ()
  return ( 
    <>
      <ul className="nav web-nav">
        <li>
          <a href="javascript:void(0)" onClick={()=>{navigate('/')}}>Dashboard</a>
        </li>
        <li>
          <a href="javascript:void(0)" onClick={()=>{navigate('/')}}>Request Ride</a>
        </li>
        <li>
          <a href="javascript:void(0)">Scheduled</a>
        </li>
        <li>
          <a href="javascript:void(0)">History</a>
        </li>
        <li>
          <a href="javascript:void(0)">FAQ</a>
        </li>
        <li>
          <a href="javascript:void(0)">Support</a>
        </li>
        <li>
          <a href="javascript:void(0)">Account</a>
        </li>
      </ul>
      <a href="javascript:void(0" className="notification-link">
        <i className="fa fa-bell"></i>
      </a>
      <a href="javascript:void(0" onClick={handleToggle} className="responsive-menu-btn">
        <i className={isActive ? "fa fa-bars" : "fa fa-times"}></i>
      </a>
       
      <ul className={isActive ? "nav mob-nav hide" : "nav mob-nav show"}  >
        <li>
          <a href="javascript:void(0)" onClick={()=>{navigate('/')}}>Dashboard</a>
        </li>
        <li>
          <a href="javascript:void(0)" onClick={()=>{navigate('/')}}>Request Ride</a>
        </li>
        <li>
          <a href="javascript:void(0)">Scheduled</a>
        </li>
        <li>
          <a href="javascript:void(0)">History</a>
        </li>
        <li>
          <a href="javascript:void(0)">FAQ</a>
        </li>
        <li>
          <a href="javascript:void(0)">Support</a>
        </li>
        <li>
          <a href="javascript:void(0)">Account</a>
        </li>
      </ul>
    </>
  );
};

export default HeaderMenu;
