import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
 

const VehicleSelect = () => {
  const [selectVehicle, setSelectVehicle] = useState([]);
  const [status, setStatus] = useState(0);
  useEffect(() => {
    let data = JSON.parse(localStorage.getItem("data"));
    let fleet = data.fleets;
    // console.log(data);
    setSelectVehicle(fleet);
    localStorage.setItem('quote_info_record_id', data.quote_info_record_id);
    localStorage.setItem('maps_record_id', data.maps_record_id);
    localStorage.setItem('fleet_record_with_rate_id', data.fleet_record_with_rate_id);
    localStorage.setItem('fleet_id', data.fleets[0].fleet_id);
  }, []);

  return (
    <>
      <div className="vehicle-select">
        <Link to="/vehicleselection ">
          {selectVehicle.map((data, index) => {
            return (
              <div
                className={"d-flex justify-content-between align-items-center vehicle-select-align "+(status == index ? 'active' : '')}
                key={index} onClick={e => {localStorage.setItem('fleet_id', data.fleet_id); setStatus(index)}}
              >
                <div className="vehicle-img">
                  <div className="vehicle-desc">
                    <p>{data.name} &nbsp;  &nbsp;
                    <span> 
                    <i className="fas fa-user"></i> {data.info.passengers}&nbsp;&nbsp;
                      <i className="fas fa-suitcase"></i> {data.info.luggage}
                  </span></p> 
                  </div>
                  <img src={data.info.img_link} alt=""></img>
                  <p>{data.info.desc}</p>
                </div>
                <div className="vehicle-fare">
                  
                  <p>
                    <strong>${data.fare_details.total_fare.toFixed(2)}</strong>
                  </p>
                </div>
              </div>
            );
          })}
        </Link>
      </div>
    </>
  );
};

export default VehicleSelect;
