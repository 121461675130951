import React, { useState } from "react";
import JourneyDesc from "../Components/VehicelExtraInfoComp.js/JourneyDesc";
import BtnCustom from "../Components/BtnCustom";
import Logo from "../Components/Logo"; 
import HeaderMenu from "../Components/HeaderMenu";
import VehicleSelect from "../Components/VehicleSelectionComp/VehicleSelect";

const VehicleSelectionPage = () => {
  const [paymentType, setPaymentType] = useState(false);
  localStorage.removeItem('vehicleSelection');
  return (
    <>
      <header>
        <Logo />
        <HeaderMenu />
      </header>
      <div className="container-fluid dim-bg">
      <div className="row ">
      <div className="col-md-6">
        <JourneyDesc paymentType={paymentType} backTo='/calender' />
        </div>
        <div className="col-md-6">
          <div className="floating-sidebar" style={{ zIndex: 1 }}>
            {/* <h1>Choose Vehicle</h1> */}
            <VehicleSelect />
            <BtnCustom name={"Proceed"} path={"vehicleextra"} />
          </div>
        </div>
        
      </div>
      </div>
    </>
  );
};

export default VehicleSelectionPage;
