import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { postRequest } from "../Helpers/APIHelper";
import Logo from "../Components/Logo";
import HeaderMenu from "../Components/HeaderMenu";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";

const MakePayment = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [btnDisable, setBtnDisable] = useState(false);
  const [amount, setAmount] = useState(0);
  const [formData, setFormData] = useState({
    booking_ref_id: "",
    card_number: "",
    expiry_year: "",
    expiry_month: "",
    card_code: "",
    card_holder_name: "",
    type: "not_stored",
  });
  const [showMsg, setShowMsg] = useState(true);
  const [msg, setMsg] = useState(
    "Please Wait! Fetching booking information..."
  );

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [customMessage, setCustomMessage] = useState("");

  // Error
  const [showError, setShowError] = useState(false);
  const handleErrorClose = () => setShowError(false);
  const handleErrorShow = () => setShowError(true);
  const [customError, setCustomError] = useState("");

  let data = { invoice_id: searchParams.get("invoice_id") };

  useEffect(() => {
    postRequest("quote/invoice", data).then((res) => {
      console.log(res);
      if (res.status === 1) {
        setAmount(parseFloat(res.data.invoice_amount).toFixed(2));
        formData.booking_ref_id = res.data.booking_id;
        setShowMsg(false);
      } else {
        setShowMsg(true);
        setMsg(res.message);
      }
    });
  }, []);

  const handleChange = (event) => {
    event.preventDefault();
    formData[event.target.name] = event.target.value;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(formData);

    let isValid = true;

    Object.keys(formData).map((key, index) => {
      if (!formData[key]) {
        isValid = false;
        // alert(key.replace(/_/g, " ") + " field is empty.");
      }
    });

    if (!isValid) {
      setShowError(true);
      setCustomError('Please fill all the fields.');
      return;
    }
    setBtnDisable(true);
    postRequest("quote/make-card-payment", formData).then((res) => {
      setBtnDisable(false);
      if (res.status === 1) {
        setShow(true);
        setCustomMessage(res.message);
      } else {
        setShowError(true);
        setCustomError(res.message);
      }
    });
  };

  return (
    <>
      {btnDisable ? <div className="loading"></div> : ""}
      <header>
        <Logo />
        <HeaderMenu />
      </header>
      <div className="payment-form-wrapper">
        {showMsg ? (
          <h1>
            {" "}
            <i className="fa fa-exclamation-circle"></i> {msg}
          </h1>
        ) : (
          <div className="form-wrap">
            <h3>Secure Payment</h3>
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>
                      Card Number <span className="text-danger">*</span>
                    </label>

                    <input
                      type="text"
                      className="form-control"
                      name="card_number"
                      placeholder="XXXX-XXXX-XXXX-XXXX"
                      maxlength="20"
                      required=""
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>
                      Card Expiry Year/Month{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <div className="row">
                      <div className="col-sm-6">
                        <input
                          type="text"
                          className="form-control"
                          name="expiry_month"
                          placeholder="MM"
                          min="1"
                          max="12"
                          maxlength="2"
                          required=""
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-sm-6">
                        <input
                          type="text"
                          className="form-control"
                          name="expiry_year"
                          placeholder="YY"
                          min={new Date().getFullYear().toString().substr(-2)}
                          max="99"
                          maxlength="2"
                          required=""
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>
                      CCV <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="card_code"
                      maxlength="4"
                      placeholder="123"
                      required=""
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label>
                      Card Holder's Full Name{" "}
                      <span className="text-danger">*</span>
                    </label>

                    <input
                      type="text"
                      className="form-control"
                      name="card_holder_name"
                      placeholder="Enter Full Name"
                      required=""
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="form-group mb-0">
                    <button
                      type="submit"
                      class="btn-main"
                      disabled={btnDisable}
                    >
                      Checkout $ {amount}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        )}
      </div>

      <Modal show={show} onHide={handleClose} backdrop="static">
        {/* <Modal.Header closeButton></Modal.Header> */}
        <Modal.Body className="text-center pt-3">
          <Modal.Title className="custom-modal-title mb-3">
            Success!
          </Modal.Title>
          <p className="text-center fs-14">{customMessage}</p>
          <Link to="/" className="booking-btn-conform">
            OK
          </Link>
        </Modal.Body>
      </Modal>

      <Modal show={showError} onHide={handleErrorClose}>
        <Modal.Body className="text-center">
          <Modal.Title className="custom-modal-title text-danger">
            SORRY!
          </Modal.Title>
          <p className="text-center fs-14">{customError}</p>
          <button
            className="booking-btn-conform btn btn-danger"
            onClick={handleErrorClose}
          >
            OK
          </button>
          {/* <Link to="/" className="booking-btn-conform btn btn-danger">
            OK
          </Link> */}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default MakePayment;
