import React, { useState } from "react";
import CalenderDate from "../Components/CalenderDate/CalenderDate";
import Logo from "../Components/Logo";
import HeaderMenu from "../Components/HeaderMenu";
import BtnCustom from "../Components/BtnCustom";
import Capacity from "../Components/VehicelExtraInfoComp.js/Capacity";
import Add from "../Components/VehicelExtraInfoComp.js/Add";
import JourneyDesc from "../Components/VehicelExtraInfoComp.js/JourneyDesc";
const CalenderPage = () => {
  const [paymentType, setPaymentType] = useState(false);
  
  return (
    <>
      <header>
        <Logo />
        <HeaderMenu />
      </header>
      <div className="container-fluid dim-bg">
        <div className="row">
          <div className="col-md-6">
            <JourneyDesc paymentType={paymentType} backTo='/' />
          </div>
          <div className="col-md-6">
            <div className="floating-sidebar" style={{ zIndex: 1 }}>
              <div className="extrawrapper"> 
                <CalenderDate />
                <Capacity type={"capacity"} /> 
                <Add type={"add"} />
              </div>
              <BtnCustom 
                name={"Next"}
                path={"vehicleselection"}
                style={{ zIndex: 1 }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CalenderPage;
