import React from 'react'
import Backbtn from '../Components/VehicleSelectionComp/Backbtn'
import Map from '../Components/Map'

const PaymentSuccess = () => {
    return (
        <>
         <div className="row vehicle-extra-info-page gx-0">
        <div className="col-xs-3 col-lg-3 col-md-6 col-sm-6">
          <Backbtn heading={"Payment Success"} backbtnpath={"paymentsuccess"} />
          <div className="left-content">

          </div>
        </div>
        <div className="col-xs-9 col-lg-9 col-md-6 col-sm-6">
          <Map />
        </div>
      </div>
            
        </>
    )
}

export default PaymentSuccess
