import React, { useEffect, useState } from "react";
import InputField from "./InputField";
import BtnCustom from "../BtnCustom";

const HomeForm = ({setMapPlotData}) => {
  const [toggler, setToggler] = useState(false);
  const [airport, setAirport] = useState("from_airport");
  const [coordinates, setCoordinates] = useState({ lat: null, lng: null });
  const [formErrors, setFormErrors] = useState({});
  const TogglerHandler = () => {
    setToggler(!toggler);
  };

  useEffect(() => {
    // localStorage.clear();
    localStorage.setItem('fromTo',"from_airport");
    localStorage.setItem('booster_seat', 0);
    localStorage.setItem('regular_seat', 0);
    localStorage.setItem('infant_seat', 0);
    localStorage.setItem('passengers', 1);
    localStorage.setItem('luggage', 0);
    localStorage.setItem('currentTime', "01:32pm");
    localStorage.setItem('destinationRef', null);
    localStorage.setItem('startLat', 42.3656132);
    localStorage.setItem('startLng', -71.0095602);
    localStorage.setItem('start', "Boston, MA 02128, USA");
    localStorage.setItem('originRef', "Boston Logan International Airport (BOS), Boston, MA, USA");
  },[]);

  const fromToAirport = localStorage.getItem('fromTo');
  const getDefaultLocation = "Boston Logan International Airport";

  // From To Airport
  function onValueChange(event) {
    setAirport(event.target.id);
    localStorage.setItem('fromTo',event.target.id);
    if(event.target.id === "to")
    {
      localStorage.setItem('endLat', 42.3656132);
      localStorage.setItem('endLng', -71.0095602);
      localStorage.setItem('end', "Boston Logan International Airport (BOS), Boston, MA, USA");
      localStorage.setItem('destinationRef', "Boston Logan International Airport (BOS), Boston, MA, USA");
      localStorage.removeItem('startLat');
      localStorage.removeItem('startLng');
      localStorage.removeItem('start');
      localStorage.removeItem('originRef');
    }
    else
    {
      localStorage.setItem('startLat', 42.3656132);
      localStorage.setItem('startLng', -71.0095602);
      localStorage.setItem('start', "Boston Logan International Airport (BOS), Boston, MA, USA");
      localStorage.setItem('originRef', "Boston Logan International Airport (BOS), Boston, MA, USA");
      localStorage.removeItem('endLat');
      localStorage.removeItem('endLng');
      localStorage.removeItem('end');
      localStorage.removeItem('destinationRef');
    }
  }

  return (
    <>
      <div className="sidebar-booking">
        <form>
          <div className="from-to">
            <div className="from">
              <input type="radio" id="from_airport" name="airport-way" checked={airport === "from_airport"} onChange={onValueChange}/>
              <label className="btn btn-default" for="from_airport">
                From <span>Airport</span>
              </label>
            </div>
            <div className="to">
              <input type="radio" id="to" name="airport-way" checked={airport === "to"} onChange={onValueChange} />
              <label className="btn btn-default" for="to">
                To <span>Airport</span>
              </label>
            </div>
          </div>

          <div className="field-wrapper start-form-wrapper">
            {fromToAirport==="from_airport" ? (
              <div className="input-group">
                <div className="input-group-prepend ">
                  <span className="input-group-text ">
                    <span className="start-dot"></span>
                  </span>
                </div>
                <input type='text' className="form-control br-0 inputstyle" placeholder="Pickup Address" value={getDefaultLocation} readOnly />
              </div>
            ) : <InputField
              go="Pickup"
              dot="start-dot"
              TogglerHandler={TogglerHandler}
              setCoordinates={setCoordinates}
              setMapPlotData={setMapPlotData}
            />}
            <p className="text-danger">{formErrors.Pickup}</p>
           
            {fromToAirport==="to" ?(
            <div className="input-group">
            <div className="input-group-prepend ">
              <span className="input-group-text ">
                <span className="end-dot"></span>
              </span>
            </div>
            <input type='text' className="form-control br-0 inputstyle" placeholder="Drop Off Address" value={getDefaultLocation} readOnly />
          </div>
            ) : <InputField
              go="Dropoff"
              dot="end-dot"
              TogglerHandler={TogglerHandler}
              setCoordinates={setCoordinates}
              setMapPlotData={setMapPlotData}
            />}
            <p className="text-danger">{formErrors.Dropoff}</p>
          </div>
          <BtnCustom name={"Request A Ride"} path={"calender"} setForm={setFormErrors} />
        </form>
      </div>
    </>
  );
};

export default HomeForm;
