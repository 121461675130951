import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Modal";
import { Link, useNavigate } from "react-router-dom";
import { postRequest } from "../../Helpers/APIHelper";

const ModalConfirmBtn = ({ confirmStatus }) => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [disableShow, setDisableShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setDisableShow(true);
  const handleDisableClose = () => setDisableShow(false);
  const [booking, setBooking] = useState([]);

  const [showBasicInfo, setShowBasicInfo] = useState(false);
  const handleBasicInfoClose = () => setShowBasicInfo(false);
  const handleBasicInfoShow = () => setShowBasicInfo(true);

  const [showFlightInfo, setShowFlightInfo] = useState(false);
  const handleFlightInfoClose = () => setShowFlightInfo(false);
  const handleFlightInfoShow = () => setShowFlightInfo(true);

  const [showInfo, setShowInfo] = useState(false);
  const handleInfoClose = () => setShowInfo(false);
  const handleInfoShow = () => setShowInfo(true);

  const [showError, setShowError] = useState(false);
  const handleErrorClose = () => setShowError(false);
  const handleErrorShow = () => setShowError(true);
  const [customError, setCustomError] = useState('');
  const [customMessage, setCustomMessage] = useState('');
  const [btnDisable, setBtnDisable] = useState(false);

  // const clearData = () => localStorage.clear();

  function handleConfirm(e) {
    e.preventDefault();
    const data = JSON.parse(localStorage.getItem("vehicleSelection"));
    if (localStorage.getItem("payment_method") == 'Paypal') {
      confirmStatus = true;
    }

    if (confirmStatus === true) {
      const name = localStorage.getItem('username')
      const email = localStorage.getItem('email')
      const phone = localStorage.getItem('phone')
      const flight_name = localStorage.getItem('flight_name')
      const flight_number = localStorage.getItem('flight_number')

      if (localStorage.getItem('fromTo') === 'from_airport') {
        if (name && email && phone && flight_name && flight_number) {
          const booking = {
            name: localStorage.getItem("username"),
            phone: localStorage.getItem("phone"),
            email: localStorage.getItem("email"),
            flight_number: localStorage.getItem("flight_number"),
            flight_name: localStorage.getItem("flight_name"),
            payment_method: localStorage.getItem("payment_method"),
            quote_info_record_id: data.quote_info_record_id,
            maps_record_id: data.maps_record_id,
            selected_vehicle_record_id: data.selected_vehicle_record_id,
            payment_response_data: JSON.parse(localStorage.getItem('payment_response_data'))
          };
          setBtnDisable(true);
          postRequest('quote/confirm', booking).then(res => {
            if (res.status === 1) {
              // setShow(true);
              let finishBooking = {
                'quote_info_record_id': res.data.quote_info_record_id,
                'selected_vehicle_record_id': res.data.selected_vehicle_record_id,
                'booking_info_record_id': res.data.booking_info_record_id
              }
              postRequest('quote/finish', finishBooking).then(res => {
                // console.log(res);
                // setBtnDisable(false);
                // if(res.status === 1)
                // {
                // setShow(true);
                // setCustomMessage(res.message);
                localStorage.clear();

                const domainList = {
                  "booking.gobostonloganairport.com": { prefix: "GOBOS", domain_id: 1 },
                  "booking.bostonairportexpresscar.com": { prefix: "BAEC", domain_id: 2 },
                  "booking.airporttaxima.com": { prefix: "ATM", domain_id: 3 },
                  "booking.bostonairportcheapcar.com": { prefix: "BACC", domain_id: 4 },
                  "booking.loganairportcar.com": { prefix: "LAC", domain_id: 5 },
                  "booking.bostonloganairportcab.com": { prefix: "BLAC", domain_id: 6 },
                  "booking.natickairporttaxi.com": { prefix: "NAT", domain_id: 7 },
                  "booking.concordairporttaxi.com": { prefix: "CON", domain_id: 8 },
                  "booking.airportcarlexington.com": { prefix: "LEX", domain_id: 9 },
                  "booking.walthamairporttaxi.com": { prefix: "WAL", domain_id: 10 },
                  "booking.airporttaxiarlington.com": { prefix: "ARL", domain_id: 11 },
                  "booking.airporttaxicambridgema.com": { prefix: "CAM", domain_id: 12 },
                  "booking.airporttaxiandover.com": { prefix: "ADOV", domain_id: 13 },
                  "booking.newtonairporttaxima.com": { prefix: "NEW", domain_id: 14 },
                  "booking.actonairporttaxi.com": { prefix: "AAT", domain_id: 15 },
                  "booking.hudsonairporttaxi.com": { prefix: "HUD", domain_id: 16 },
                  "booking.bosairportlimos.com": { prefix: "BLIMO", domain_id: 17 },
                  "booking.westonairporttaxima.com": { prefix: "WEST", domain_id: 18 },
                  "booking.wilmingtonairporttaxis.com": { prefix: "WAT", domain_id: 19 },
                  "booking.bostonairportcarma.com": { prefix: "BACMA", domain_id: 20 },
                  "booking.bostonairporttaxis.com": { prefix: "BAT", domain_id: 21 },
                  "booking.gologanairport.com": { prefix: "GLA", domain_id: 22 },
                  "booking.gobostonairport.com": { prefix: "GBA", domain_id: 23 },
                  "booking.worcesterairporttaxi.com": { prefix: "WOC", domain_id: 24 },
                  "booking.loganexpresscar.com": { prefix: "LEC", domain_id: 25 },
                };
                const domainName = window.location.hostname;
                localStorage.setItem("domain_id", 22);
                if (domainList[domainName] ?? false) {
                  localStorage.setItem("domain_id", domainList[domainName].domain_id);
                }

                localStorage.setItem('fromTo', "from_airport");
                localStorage.setItem('finishMsg', res.message);
                localStorage.setItem('refId', (res.data ? res.data.booking_ref_id : ''));
                localStorage.setItem('startLat', 42.3656132);
                localStorage.setItem('startLng', -71.0095602);
                localStorage.setItem('start', "Boston Logan International Airport (BOS), Boston, MA, USA");
                localStorage.setItem('originRef', "Boston Logan International Airport (BOS), Boston, MA, USA");
                localStorage.setItem('finishMsg', res.message);
                localStorage.setItem('refId', (res.data ? res.data.booking_ref_id : ''));
                if (res.data.paypal_redirect_link ?? false) {
                  console.log(res.data.paypal_redirect_link, '--------------------');
                  window.location.href = res.data.paypal_redirect_link;
                } else {
                  navigate('/thank-you');
                }
                // }
                // else
                // {
                //   setShowError(true);
                //   setCustomError(res.message);
                // }
              });
            }
            else {
              setBtnDisable(false);
              setShowError(true);
              setCustomError(res.message);
            }
          });
        }
        else if ((!name || !email || !phone) && (!flight_name || !flight_number)) {
          handleInfoShow()
        }
        else if (!name || !email || !phone) {
          handleBasicInfoShow()
        }
        else if (!flight_name || !flight_number) {
          handleFlightInfoShow()
        }
      }
      else {
        if (name && email && phone) {
          const booking = {
            name: localStorage.getItem("username"),
            phone: localStorage.getItem("phone"),
            email: localStorage.getItem("email"),
            flight_number: localStorage.getItem("flight_number"),
            flight_name: localStorage.getItem("flight_name"),
            payment_method: localStorage.getItem("payment_method"),
            quote_info_record_id: data.quote_info_record_id,
            maps_record_id: data.maps_record_id,
            selected_vehicle_record_id: data.selected_vehicle_record_id,
            payment_response_data: JSON.parse(localStorage.getItem('payment_response_data'))
          };
          setBtnDisable(true);
          postRequest('quote/confirm', booking).then(res => {
            if (res.status === 1) {
              // setShow(true);
              let finishBooking = {
                'quote_info_record_id': res.data.quote_info_record_id,
                'selected_vehicle_record_id': res.data.selected_vehicle_record_id,
                'booking_info_record_id': res.data.booking_info_record_id
              }
              postRequest('quote/finish', finishBooking).then(res => {
                // console.log(res);
                // setBtnDisable(false);
                // if(res.status === 1)
                // {
                // setShow(true);
                // setCustomMessage(res.message);
                localStorage.clear();
                const domainList = {
                  "booking.gobostonloganairport.com": { prefix: "GOBOS", domain_id: 1 },
                  "booking.bostonairportexpresscar.com": { prefix: "BAEC", domain_id: 2 },
                  "booking.airporttaxima.com": { prefix: "ATM", domain_id: 3 },
                  "booking.bostonairportcheapcar.com": { prefix: "BACC", domain_id: 4 },
                  "booking.loganairportcar.com": { prefix: "LAC", domain_id: 5 },
                  "booking.bostonloganairportcab.com": { prefix: "BLAC", domain_id: 6 },
                  "booking.natickairporttaxi.com": { prefix: "NAT", domain_id: 7 },
                  "booking.concordairporttaxi.com": { prefix: "CON", domain_id: 8 },
                  "booking.airportcarlexington.com": { prefix: "LEX", domain_id: 9 },
                  "booking.walthamairporttaxi.com": { prefix: "WAL", domain_id: 10 },
                  "booking.airporttaxiarlington.com": { prefix: "ARL", domain_id: 11 },
                  "booking.airporttaxicambridgema.com": { prefix: "CAM", domain_id: 12 },
                  "booking.airporttaxiandover.com": { prefix: "ADOV", domain_id: 13 },
                  "booking.newtonairporttaxima.com": { prefix: "NEW", domain_id: 14 },
                  "booking.actonairporttaxi.com": { prefix: "AAT", domain_id: 15 },
                  "booking.hudsonairporttaxi.com": { prefix: "HUD", domain_id: 16 },
                  "booking.bosairportlimos.com": { prefix: "BLIMO", domain_id: 17 },
                  "booking.westonairporttaxima.com": { prefix: "WEST", domain_id: 18 },
                  "booking.wilmingtonairporttaxis.com": { prefix: "WAT", domain_id: 19 },
                  "booking.bostonairportcarma.com": { prefix: "BACMA", domain_id: 20 },
                  "booking.bostonairporttaxis.com": { prefix: "BAT", domain_id: 21 },
                  "booking.gologanairport.com": { prefix: "GLA", domain_id: 22 },
                  "booking.gobostonairport.com": { prefix: "GBA", domain_id: 23 },
                  "booking.worcesterairporttaxi.com": { prefix: "WOC", domain_id: 24 },
                  "booking.loganexpresscar.com": { prefix: "LEC", domain_id: 25 },
                };
                const domainName = window.location.hostname;
                localStorage.setItem("domain_id", 22);
                if (domainList[domainName] ?? false) {
                  localStorage.setItem("domain_id", domainList[domainName].domain_id);
                }

                localStorage.setItem('fromTo', "from_airport");
                localStorage.setItem('finishMsg', res.message);
                localStorage.setItem('refId', (res.data ? res.data.booking_ref_id : ''));
                localStorage.setItem('startLat', 42.3656132);
                localStorage.setItem('startLng', -71.0095602);
                localStorage.setItem('start', "Boston Logan International Airport (BOS), Boston, MA, USA");
                localStorage.setItem('originRef', "Boston Logan International Airport (BOS), Boston, MA, USA");
                localStorage.setItem('finishMsg', res.message);
                localStorage.setItem('refId', (res.data ? res.data.booking_ref_id : ''));
                if (res.data.paypal_redirect_link ?? false) {
                  window.location.href = res.data.paypal_redirect_link;
                } else {
                  navigate('/thank-you');
                }
                // }
                // else
                // {
                //   setShowError(true);
                //   setCustomError(res.message);
                // }
              });
            }
            else {
              setShowError(true);
              setCustomError(res.message);
            }
          });
        }
        else {
          handleBasicInfoShow()
        }
      }

    }
    else {
      handleShow();
    }
  }

  return (
    <>
      {btnDisable ? <div className="loading"></div> : ''}
      <div className="fix-btn-bg confirm-btn">
        <button className="btn-main" onClick={e => { handleConfirm(e) }}>
          Confirmed
        </button>
      </div>
      <Modal show={show} onHide={handleClose} backdrop="static">
        {/* <Modal.Header closeButton></Modal.Header> */}
        <Modal.Body className="text-center pt-3">
          <Modal.Title className="custom-modal-title mb-3">
            Confirmed Success!
          </Modal.Title>
          <p className="text-center fs-14">
            {customMessage}
          </p>
          <Link to="/" className="booking-btn-conform">
            OK
          </Link>
        </Modal.Body>
      </Modal>

      {/* Add Payment Alert */}
      <Modal show={disableShow} onHide={handleDisableClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="text-center">
          <Modal.Title className="custom-modal-title">
            Please Add Payment!
          </Modal.Title>
          <p className="text-center fs-14">
            Before you confirm the booking.
          </p>
          <button className="booking-btn-conform" onClick={handleDisableClose}>
            OK
          </button>
        </Modal.Body>
      </Modal>

      {/* Add Basic Information Alert */}
      <Modal show={showBasicInfo} onHide={handleBasicInfoClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="text-center">
          <Modal.Title className="custom-modal-title">
            Basic Information Empty!
          </Modal.Title>
          <p className="text-center fs-14">
            Please fill up all the input field.
          </p>
          <button className="booking-btn-conform" onClick={handleBasicInfoClose}>
            OK
          </button>
        </Modal.Body>
      </Modal>

      {/* Add Flight Information Alert */}
      <Modal show={showFlightInfo} onHide={handleFlightInfoClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="text-center">
          <Modal.Title className="custom-modal-title">
            Flight Information Empty!
          </Modal.Title>
          <p className="text-center fs-14">
            Please fill up all the input field.
          </p>
          <button className="booking-btn-conform" onClick={handleFlightInfoClose}>
            OK
          </button>
        </Modal.Body>
      </Modal>

      {/* Add Flight Information Alert */}
      <Modal show={showInfo} onHide={handleInfoClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="text-center">
          <Modal.Title className="custom-modal-title">
            Information Empty!
          </Modal.Title>
          <p className="text-center fs-14">
            Please fill up Basic Information and Flight Information.
          </p>
          <button className="booking-btn-conform" onClick={handleInfoClose}>
            OK
          </button>
        </Modal.Body>
      </Modal>

      {/* Error Model */}
      <Modal show={showError} onHide={handleErrorClose}>
        <Modal.Body className="text-center">
          <Modal.Title className="custom-modal-title text-danger">
            SORRY!
          </Modal.Title>
          <p className="text-center fs-14">
            {customError}
          </p>
          {/* <button className="booking-btn-conform btn btn-danger" onClick={handleErrorClose}>
              OK
          </button> */}
          <Link to="/" className="booking-btn-conform btn btn-danger">
            OK
          </Link>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalConfirmBtn;
