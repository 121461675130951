import React, { useState } from "react";
import SelectItem from "./SelectItem";
 
const Add = () => {
  const [show, setShow] = useState(false);
  return (
    <>
    
        <h3 className="d-flex justify-content-between">
          Add Extras 
          <div className="form-group">
            <input
              onClick={() => setShow(!show)}
              type="checkbox"
              id="extras"
              className="chkbx-toggle"
              value="1"
            />
            <label for="extras"></label>
          </div>
        </h3>
        {show ? (
          <div className="row mt-2 select-col-3">
            <div className="col-md-4">
            <SelectItem name="Boaster Seat" icon="fas fa-baby-carriage" type={"booster_seat"} />
            </div>
            <div className="col-md-4">
            <SelectItem name="Handicapp" icon="fas fa-wheelchair" type={"regular_seat"} />
            </div>
            <div className="col-md-4">
            <SelectItem name="Pet" icon="fas fa-baby" type={"infant_seat"} />
            </div>
          </div>
        ) : null} 
    </>
  );
};

export default Add;
