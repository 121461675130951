import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { postRequest } from "../Helpers/APIHelper";
import Logo from "../Components/Logo";
import HeaderMenu from "../Components/HeaderMenu";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { config } from '../Helpers/Constants'


const ThankYou = () => {
  let msg = localStorage.getItem("finishMsg");
  let refId = localStorage.getItem("refId");
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [showError, setShowError] = useState(false);
  const handleErrorClose = () => setShowError(false);
  const handleErrorShow = () => setShowError(true);
  const [customError, setCustomError] = useState("");
  const [finishMsg, setFinishMsg] = useState(msg);

  // const apiUrl =  'https://dispatch.gologanairport.com/api/';
  // const apiUrl = "http://gologanairport.test/api/";
  const apiUrl = config.API_URL;

  useEffect(() => {
    if (!finishMsg) {
      navigate("/");
    }
    localStorage.removeItem("finishMsg");
    localStorage.removeItem("refId");
  });

  return (
    <>
      <header>
        <Logo />
        <HeaderMenu />
      </header>

      <div className="container-fluid dim-bg thankyou-page">
        <div className="floating-sidebar text-center">
          <h1>Thank You! </h1>
          <p>{finishMsg}</p>
          {refId ? (
            <p>
              You can{" "}
              <a
                class="pdf-section"
                href={apiUrl + "quote/download-pdf?booking_ref_id=" + refId}
              >
                <i class="fa fa-file-pdf"></i> download the pdf here.
              </a>{" "}
              or{" "}
              <a
                class="pdf-section"
                href={
                  apiUrl +
                  "quote/download-pdf?booking_ref_id=" +
                  refId +
                  "&print=1"
                }
                target="_blank"
              >
                <i class="fa fa-print"></i> Print the invoice.
              </a>
            </p>
          ) : (
            ''
          )}

          <Link to="/" className="btn-main">
            Back to Homepage
          </Link>
        </div>
      </div>

      <Modal show={showError} onHide={handleErrorClose}>
        <Modal.Body className="text-center">
          <Modal.Title className="custom-modal-title text-danger">
            SORRY!
          </Modal.Title>
          <p className="text-center fs-14">{customError}</p>
          <button
            className="booking-btn-conform btn btn-danger"
            onClick={handleErrorClose}
          >
            OK
          </button>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ThankYou;
