import React from "react";
import DriverProfile from "../Components/DriverOnDestinationComp/DriverProfile";

import Map from "../Components/Map";

const DriverOnDestination = () => {
  return (
    <>
      <div className="row driver-on-destination-page gx-0">
        <div className="col-xs-3 col-lg-3 col-md-6 col-sm-6">
          <div className="left-content">
              <p className="driver-on-destination-distance">1 hr 20 mins away, 273.4 mis</p>
              <DriverProfile/>
          </div>
        </div>
        <div className="col-xs-9 col-lg-9 col-md-6 col-sm-6">
          <Map />
        </div>
      </div>
    </>
  );
};

export default DriverOnDestination;
