import React from "react";
import SelectItem from "./SelectItem";

const Capacity = () => {
  return (
    <>  
        <div className="row mt-3">
            <div className="col-md-6">
            <SelectItem name="Passanger" icon="fas fa-user" type={"passengers"} />
            </div>
            <div className="col-md-6">
            <SelectItem name="Luggage" icon="fas fa-suitcase" type={"luggage"} />
            </div>
        </div> 
    </>
  );
};

export default Capacity;
