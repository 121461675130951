import axios from 'axios';
import {encode as base64_encode} from 'base-64';
import { config } from '../Helpers/Constants'

// const apiUrl =  'https://dispatch.gologanairport.com/api/';
// const apiUrl =  'http://gologanairport.test/api/';
const apiUrl = config.API_URL;

export const axiosHeader = () => {
  let username = 'dispatch_api';
  let password = 'sKi1ax>H92XxP6g';
  let basicAuth = "Basic "+base64_encode(username + ':' + password);
  return {
    headers: {
      // 'Content-Type': 'application/json'
      // 'Authorization': basicAuth,
    }
  }
}

export const getRequest = (url) => {
  return axios.get(apiUrl+url,axiosHeader).then(res => res.data);
};

export const postRequest = (url, data) => {
    // console.log(data);
    return axios.post(apiUrl+url, data, axiosHeader).then(res => res.data).catch(err => err.response.data);
}