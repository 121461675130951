import React, { useEffect, useState } from "react";
import VehicleInput from "./VehicleInput";

const FlightInformation = () => {
  const [show, setShow] = useState(false);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("fromTo") === "from_airport") {
      setShow(true);
      setChecked(true);
    }
  }, []);

  // function handleToggle(e) {
  //   setChecked(!checked);
  //   setShow(!show)
  // }
  return (
    <>
      {show ? (
        <>
          <div className="col-md-6 mb-3">
            <VehicleInput
              icon={"fa-plane-departure"}
              placeholder={"Flight Name"}
              type="name"
            />
          </div>
          <div className="col-md-6 mb-3">
            <VehicleInput
              icon={"fa-plane"}
              placeholder={"Flight Number"}
              type="number"
            />
          </div>
          {/* ) : null} */}
        </>
      ) : null}
    </>
  );
};

export default FlightInformation;
