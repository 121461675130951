import "./App.css";
import Home from "./Pages/Home";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import VehicleSelectionPage from "./Pages/VehicleSelectionPage";
import CalenderPage from "./Pages/CalenderPage";
import QuotePage from "./Pages/QuotePage";
import MakePayment from "./Pages/MakePayment";
import VehicleExtraInfo from "./Pages/VehicleExtraInfo";
import PaymentSuccess from "./Pages/PaymentSuccess";
import DriverOnDestination from "./Pages/DriverOnDestination";
import { useJsApiLoader } from "@react-google-maps/api";
import { useEffect } from "react";
import ThankYou from "./Pages/ThankYou";
// import TestCalendar from "./Pages/TestCalendar";

function App() {
   useEffect(() => {
    const domainList = {
      "booking.gobostonloganairport.com": { prefix: "GOBOS", domain_id: 1 },
      "booking.bostonairportexpresscar.com": { prefix: "BAEC", domain_id: 2 },
      "booking.airporttaxima.com": { prefix: "ATM", domain_id: 3 },
      "booking.bostonairportcheapcar.com": { prefix: "BACC", domain_id: 4 },
      "booking.loganairportcar.com": { prefix: "LAC", domain_id: 5 },
      "booking.bostonloganairportcab.com": { prefix: "BLAC", domain_id: 6 },
      "booking.natickairporttaxi.com": { prefix: "NAT", domain_id: 7 },
      "booking.concordairporttaxi.com": { prefix: "CON", domain_id: 8 },
      "booking.airportcarlexington.com": { prefix: "LEX", domain_id: 9 },
      "booking.walthamairporttaxi.com": { prefix: "WAL", domain_id: 10 },
      "booking.airporttaxiarlington.com": { prefix: "ARL", domain_id: 11 },
      "booking.airporttaxicambridgema.com": { prefix: "CAM", domain_id: 12 },
      "booking.airporttaxiandover.com": { prefix: "ADOV", domain_id: 13 },
      "booking.newtonairporttaxima.com": { prefix: "NEW", domain_id: 14 },
      "booking.actonairporttaxi.com": { prefix: "AAT", domain_id: 15 },
      "booking.hudsonairporttaxi.com": { prefix: "HUD", domain_id: 16 },
      "booking.bosairportlimos.com": { prefix: "BLIMO", domain_id: 17 },
      "booking.westonairporttaxima.com": { prefix: "WEST", domain_id: 18 },
      "booking.wilmingtonairporttaxis.com": { prefix: "WAT", domain_id: 19 },
      "booking.bostonairportcarma.com": { prefix: "BACMA", domain_id: 20 },
      "booking.bostonairporttaxis.com": { prefix: "BAT", domain_id: 21 },
      "booking.gologanairport.com": { prefix: "GLA", domain_id: 22 },
      "booking.gobostonairport.com": { prefix: "GBA", domain_id: 23 },
      "booking.worcesterairporttaxi.com": { prefix: "WOC", domain_id: 24 },
      "booking.loganexpresscar.com": { prefix: "LEC", domain_id: 25 },
    };
    const domainName = window.location.hostname;
    localStorage.setItem("domain_id", 22);
    if (domainList[domainName]??false) {
      localStorage.setItem("domain_id", domainList[domainName].domain_id);
    }
    // console.log('----------------APPPPPP-------------',localStorage.getItem("domain_id"),'----------APPPPPP---------------');
  })
  
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/vehicleselection" element={<VehicleSelectionPage />} />
        <Route path="/calender" element={<CalenderPage />} />
        <Route path="/vehicleextra" element={<VehicleExtraInfo />} />
        <Route path="/paymentsuccess" element={<PaymentSuccess />} />
        <Route path="/driverondestination" element={<DriverOnDestination />} />
        <Route path="/thank-you" element={<ThankYou />} />
        <Route path="/quote" element={<QuotePage />} />
        <Route path="/make-payment" element={<MakePayment />} />
        {/* <Route path="/testcalendar" element={<TestCalendar />} /> */}
      </Routes>
    </Router>
  );
}

export default App;
