import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Modal";
import { Link } from "react-router-dom";

const NoteToDriver = () => {
  const [show, setShow] = useState(false);
  function handleNote(e) {
    // console.log(e.target.value);
    localStorage.setItem("driverMessage", e.target.value);
  }
  return (
    <>
      <div className="col-md-12 ">
        <textarea
          className="form-control"
          rows="3"
          placeholder="Special Instruction"
          onBlur={(e) => {
            handleNote(e);
          }}
        ></textarea>
      </div>
    </>
  );
};

export default NoteToDriver;
