import React, { useState } from "react";
import VehicleInput from "./VehicleInput";
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';


const BasicInformation = () => {
  function handleInfo(e) {
    localStorage.setItem(e.target.id, e.target.value);
  }

  const onChange: IntlTelInputProps['onPhoneNumberBlur'] = (isValid, currentNumber, seletedCountryData, fullNumber) => {
    // console.log('onChange', currentNumber, seletedCountryData, fullNumber, isValid);
    let dial = seletedCountryData.dialCode;
    let userCountryCode = `+${dial}`;
    let phone = userCountryCode + currentNumber;
    // console.log('Phone', phone);
    localStorage.setItem('phone', phone);
    // if (isValid) {
    //   validatedParentNumber = fullNumber.replace(/\s|-/g, '')
    //   setValue("phone", validatedParentNumber);
    //   setIntlError(false);
    //   clearErrors('phone');
    //   let dial = selectedCountryData.dialCode;
    //   userCountryCode = `+${dial}`;
    // } else {
    //   setIntlError(true);
    //   setValue("phone", null)
    // }
  };

  const handleSelectFlag: IntlTelInputProps['onSelectFlag'] = (currentNumber, seletedCountryData, fullNumber, isValid) => {
    // console.log('onSelectFlag', currentNumber, seletedCountryData, fullNumber, isValid);
    let dial = seletedCountryData.dialCode;
    let userCountryCode = `+${dial}`;
    let phone = userCountryCode + currentNumber;
    // console.log('Phone', phone);
    localStorage.setItem('phone', phone);
    // if (isValid) {
    //   let updatedIntlNum = fullNumber.replace(/\s|-/g, '')
    //   let dial = seletedCountryData.dialCode;
    //   userCountryCode = `+${dial}`;
    //   setValue("phone", updatedIntlNum)
    //   setIntlError(false);
    //   clearErrors('phone');
    // } else {
    //   setIntlError(true);
    //   setValue("phone", null)
    // }
  };

  return (
    <>

      <div className="col-md-12">
        <div className="mt-2">
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <i className="fa fa-user"></i>
              </span>
            </div>
            <input
              type="text"
              className="form-control"
              placeholder="name"
              // value={localStorage.getItem('username')}
              id="username"
              onChange={(e) => {
                handleInfo(e);
              }}
            />
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <div className="input-group mb-3">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className="fa fa-envelope"></i>
            </span>
          </div>
          <input
            type="text"
            className="form-control"
            placeholder="email"
            // value={localStorage.getItem('email')}
            id="email"
            onChange={(e) => {
              handleInfo(e);
            }}
          />
        </div>
      </div>
      <div className="col-md-6">
        <div className="input-group mb-3">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className="fa fa-phone"></i>
            </span>
          </div>
          <span className="form-control">
            <IntlTelInput
              containerClassName="intl-tel-input"
              inputClassName="form-control"
              preferredCountries={['us']}
              onPhoneNumberChange={onChange}
              onSelectFlag={handleSelectFlag}
            />
          </span>
          {/* <input
            type="text"
            className="form-control"
            placeholder="phone"
            id="phone"
            onBlur={(e) => {
              handleInfo(e);
            }}
          /> */}
        </div>
      </div>
    </>
  );
};

export default BasicInformation;
