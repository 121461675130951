import React, { useEffect, useInsertionEffect, useState } from "react";

import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { Calendar } from "react-modern-calendar-datepicker";
import { TimePicker } from "react-rainbow-components";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import moment from "moment";

const CalenderDate = () => {
  const [selectedDay, setSelectedDay] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState(new Date());

  useEffect(() => {
    const current = new Date();
    const date =
      current.getMonth() +
      1 +
      "/" +
      current.getDate() +
      "/" +
      current.getFullYear();
    localStorage.setItem("currentDate", date);
    localStorage.setItem("todayDate", date);
  }, []);

  function getSelectedDay(params) {
    setSelectedDay(params);
    setSelectedTime(params);
    let currentSelectedDate = moment(params).format("MM/DD/YYYY");
    localStorage.setItem("currentDate", currentSelectedDate);
    localStorage.setItem("checkDate", JSON.stringify(params));
  }

  function getSelectedTime(params, ignoreZero = true) {
    setSelectedTime(params);
    let time = moment(params).format("h:mm A");
    localStorage.setItem("currentTime", time);
  }

  const isSelectedDateToday = new Date().getDate() === selectedDay.getDate();

  let minTimeHour = new Date().getHours();
  let minTimeMinute = new Date().getMinutes();
  if (!isSelectedDateToday) {
    minTimeHour = 0;
    minTimeMinute = 0;
  }

  return (
    <>
      <div className="row date-time-wrapper">
        <div className="col-md-6">
          <i className="fa fa-calendar"></i>
          <DatePicker
            selected={selectedDay}
            onChange={(date) => getSelectedDay(date)}
            minDate={new Date()}
            placeholderText="Pickup Date"
            className="form-control rainbow-m-vertical_x-large"
          />
        </div>
        <div className="col-md-6">
        <i className="fa fa-clock"></i>
          <DatePicker
            selected={selectedTime}
            minTime={new Date(new Date().setHours(minTimeHour, minTimeMinute, 0, 0))}
            maxTime={new Date(new Date().setHours(23, 59, 0, 0))}
            onChange={(date) => getSelectedTime(date)}
            showTime={{ use12Hours: true, format: "HH:mm a" }}
            showTimeSelectOnly
            showTimeSelect
            dateFormat="hh:mm a"
            placeholderText="Pickup Time"
            timeIntervals={15}
            type="text"
            className="form-control rainbow-m-vertical_x-large"
          />
        </div>
      </div>
    </>
  );
};

export default CalenderDate;
