import React from "react";

const DriverProfile = () => {
  return (
    <>
      <div className="driverprofile d-flex justify-content-between align-items-center">
        <div className="photo-rating d-flex">
          <div className="driver-photo">
              <img src="../images/driver-img.png" alt=""></img>
          </div>
          <div className="driver-rating">

          </div>
        </div>
        <div className="call-comment">
          <div className="driver-call">

          </div>
          <div className="driver-comment"></div>
        </div>
      </div>
    </>
  );
};

export default DriverProfile;
