import React from 'react'
import { Link } from 'react-router-dom'

const Backbtn = ({heading,backbtnpath}) => {
 
    return (
        <>
        <div className='mt-4 mb-4 back-btn-div d-flex'>
            <Link to={`/${backbtnpath}`} className='back-btn'><i className="fas fa-chevron-left"></i>&nbsp;<span>Back</span></Link>
            <p className='ml-3'>{heading}</p>
        </div>
        
        </>
        

    )
}

export default Backbtn
