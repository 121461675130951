import Modal from "react-bootstrap/Modal";
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { getRequest, postRequest } from '../Helpers/APIHelper';
import ModalComponent from "./ModalComponent";

// const BtnCustom = ({name,  path, estimated, price, start, end, airport}) => {
    const BtnCustom = ({name,  path, estimated, price, setForm}) => {
    const navigate = useNavigate();
    const [disableShow, setDisableShow] = useState(false);
    const handleDisableClose = () => setDisableShow(false);
    const handleShow = () => setDisableShow(true);

    const [btnDisable, setBtnDisable] = useState(false);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleVehicleShow = () => setShow(true);

    const [showError, setShowError] = useState(false);
    const handleErrorClose = () => setShowError(false);
    const handleErrorShow = () => setShowError(true);
    const [customError, setCustomError] = useState('');

    function quote(e) {
        e.preventDefault();
        const today = localStorage.getItem('todayDate');
        const selected = localStorage.getItem('currentDate');
        if(Date.parse(today)<=Date.parse(selected))
        {
            let data = {
                'start_lat': JSON.parse(localStorage.getItem('startLat')),
                'start_lng': JSON.parse(localStorage.getItem('startLng')),
                'end_lat': JSON.parse(localStorage.getItem('endLat')),
                'end_lng': JSON.parse(localStorage.getItem('endLng')),
                'service_type': localStorage.getItem('fromTo'),
                'pick_date': localStorage.getItem('currentDate'),
                'pick_time': localStorage.getItem('currentTime'),
                'start': localStorage.getItem('originRef'),
                'end': localStorage.getItem('destinationRef'),
                'booster_seat': localStorage.getItem('booster_seat'),
                'regular_seat': localStorage.getItem('regular_seat'),
                'infant_seat': localStorage.getItem('infant_seat'),
                'passengers': localStorage.getItem('passengers'),
                'luggage': localStorage.getItem('luggage'),
                'domain_id': localStorage.getItem('domain_id')
            }
            setBtnDisable(true);
            postRequest('quote',data).then(res => {
                setBtnDisable(false);
                if(res.status === 1)
                {
                    localStorage.setItem('data', JSON.stringify(res.data));
                    navigate("/"+path);
                }
                else
                {
                    setShowError(true);
                    setCustomError(res.message);
                    localStorage.removeItem('fleets');
                }
            });
        }
        else 
        {
            handleShow();
        }
    }

    function bookingInfo(e) {
        e.preventDefault();
        if(localStorage.getItem("fleet_id"))
        {
            let data = {
                'fleet_id': JSON.parse(localStorage.getItem("fleet_id")),
                'quote_info_record_id': JSON.parse(localStorage.getItem("quote_info_record_id")),
                'maps_record_id': JSON.parse(localStorage.getItem("maps_record_id")),
                'fleet_record_with_rate_id': JSON.parse(localStorage.getItem("fleet_record_with_rate_id"))
            }
            setBtnDisable(true);
            postRequest('quote/booking-info',data).then(res => {
                setBtnDisable(false);
                if(res.status === 1)
                {
                    localStorage.setItem('vehicleSelection', JSON.stringify(res.data));
                    navigate("/"+path);
                }
                else
                {
                    setShowError(true);
                    setCustomError(res.message);
                    localStorage.removeItem('fleets');
                    // navigate("/");
                }
            });
        }
        else
        {
            handleVehicleShow();
        }
    }

    function handleSubmit(e) {
        e.preventDefault();
        if(localStorage.getItem('start') !== null && localStorage.getItem('end') !== null)
        {
            navigate("/"+path);  
        }
        else
        {
            setForm(validate());
        }
    }

    function validate() {
      const errors = {};
      if(localStorage.getItem('start') === null) {
        errors.start = "Start point is required";
      }
      if(localStorage.getItem('end') === null) {
        errors.destination = "Destination point is required";
      }
      return errors;
    }

    // Link Path
    let currentLink = "";
    if(path === "vehicleselection")
    {
        currentLink = <button to={`/${path}`} className='btn-main' disabled={btnDisable} onClick={e => {quote(e)}}>{name}</button>
    }
    else if(path === "vehicleextra")
    {
        currentLink = <button className='btn-main' disabled={btnDisable} onClick={e => {bookingInfo(e)}}>{name}</button>
    }
    else
    {
        currentLink = <button className='btn-main' disabled={btnDisable} onClick={e => handleSubmit(e)}>{name}</button>
    }

    return (
        <>
        {btnDisable?<div className="loading"></div>:<div className="loading loader-inactive"></div>}
           <div className='fix-btn-bg'>
                <div className="d-flex justify-content-between mb-1">
                    <p><strong>{estimated}</strong></p>
                    <p><strong>{price}</strong></p>
                </div>
                {currentLink} 
            </div>

            <Modal show={disableShow} onHide={handleDisableClose}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body className="text-center">
                <Modal.Title className="custom-modal-title">
                    {/* Wrong Date! */}
                </Modal.Title>
                <p className="text-center fs-14">
                    Booking date should be greater than today date.
                </p>
                <button className="booking-btn-conform" onClick={handleDisableClose}>
                    OK
                </button>
                </Modal.Body>
            </Modal>

            {/* <ModalComponent /> */}

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body className="text-center">
                <Modal.Title className="custom-modal-title">
                    Select Vehcile!
                </Modal.Title>
                <p className="text-center fs-14">
                    Please select one of the vehicle from the list.
                </p>
                <button className="booking-btn-conform" onClick={handleClose}>
                    OK
                </button>
                </Modal.Body>
            </Modal>

            {/* Error Model */}
            <Modal show={showError} onHide={handleErrorClose}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body className="text-center">
                <Modal.Title className="custom-modal-title text-danger">
                    SORRY!
                </Modal.Title>
                <p className="text-center fs-14">
                    {customError}
                </p>
                <button className="booking-btn-conform btn btn-danger" onClick={handleErrorClose}>
                    OK
                </button>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default BtnCustom
