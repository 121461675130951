import React, { useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { postRequest } from "../Helpers/APIHelper";
import Logo from "../Components/Logo";
import HeaderMenu from "../Components/HeaderMenu";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";

const QuotePage = () => {
 
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const domainList = {
    "booking.gobostonloganairport.com": { prefix: "GOBOS", domain_id: 1 },
    "booking.bostonairportexpresscar.com": { prefix: "BAEC", domain_id: 2 },
    "booking.airporttaxima.com": { prefix: "ATM", domain_id: 3 },
    "booking.bostonairportcheapcar.com": { prefix: "BACC", domain_id: 4 },
    "booking.loganairportcar.com": { prefix: "LAC", domain_id: 5 },
    "booking.bostonloganairportcab.com": { prefix: "BLAC", domain_id: 6 },
    "booking.natickairporttaxi.com": { prefix: "NAT", domain_id: 7 },
    "booking.concordairporttaxi.com": { prefix: "CON", domain_id: 8 },
    "booking.airportcarlexington.com": { prefix: "LEX", domain_id: 9 },
    "booking.walthamairporttaxi.com": { prefix: "WAL", domain_id: 10 },
    "booking.airporttaxiarlington.com": { prefix: "ARL", domain_id: 11 },
    "booking.airporttaxicambridgema.com": { prefix: "CAM", domain_id: 12 },
    "booking.airporttaxiandover.com": { prefix: "ADOV", domain_id: 13 },
    "booking.newtonairporttaxima.com": { prefix: "NEW", domain_id: 14 },
    "booking.actonairporttaxi.com": { prefix: "AAT", domain_id: 15 },
    "booking.hudsonairporttaxi.com": { prefix: "HUD", domain_id: 16 },
    "booking.bosairportlimos.com": { prefix: "BLIMO", domain_id: 17 },
    "booking.westonairporttaxima.com": { prefix: "WEST", domain_id: 18 },
    "booking.wilmingtonairporttaxis.com": { prefix: "WAT", domain_id: 19 },
    "booking.bostonairportcarma.com": { prefix: "BACMA", domain_id: 20 },
    "booking.bostonairporttaxis.com": { prefix: "BAT", domain_id: 21 },
    "booking.gologanairport.com": { prefix: "GLA", domain_id: 22 },
    "booking.gobostonairport.com": { prefix: "GBA", domain_id: 23 },
    "booking.worcesterairporttaxi.com": { prefix: "WOC", domain_id: 24 },
    "booking.loganexpresscar.com": { prefix: "LEC", domain_id: 25 },
  };
  const domainName = window.location.hostname;
  localStorage.setItem("domain_id", 22);
  if (domainList[domainName]??false) {
    localStorage.setItem("domain_id", domainList[domainName].domain_id);
  }

  let data = {
    start_lat: JSON.parse(searchParams.get("start_lat")),
    start_lng: JSON.parse(searchParams.get("start_lng")),
    end_lat: JSON.parse(searchParams.get("end_lat")),
    end_lng: JSON.parse(searchParams.get("end_lng")),
    service_type: searchParams.get("service_type"),
    pick_date: searchParams.get("pick_date"),
    pick_time: searchParams.get("pick_time"),
    start: searchParams.get("from"),
    end: searchParams.get("to"),
    booster_seat: JSON.parse(searchParams.get("booster_seat")) ?? 0,
    regular_seat: JSON.parse(searchParams.get("regular_seat")) ?? 0,
    infant_seat: JSON.parse(searchParams.get("infant_seat")) ?? 0,
    passengers: JSON.parse(searchParams.get("passengers")) ?? 1,
    luggage: JSON.parse(searchParams.get("luggage")) ?? 0,
    domain_id: localStorage.getItem("domain_id") ?? 22,
  };
  
  postRequest("quote", data).then((res) => {
    // return res;
    // console.log(res.status);
    if (res.status === 1) {
      localStorage.setItem("data", JSON.stringify(res.data));
      localStorage.setItem("originRef", data.start);
      localStorage.setItem("destinationRef", data.end);
      navigate("/vehicleselection");
    } else {
      localStorage.removeItem("fleets");
      // navigate("/");
      setShowError(true);
      setCustomError(res.message);
    }
  });

  // Error
  const [showError, setShowError] = useState(false);
  const handleErrorClose = () => setShowError(false);
  const handleErrorShow = () => setShowError(true);
  const [customError, setCustomError] = useState('');
  const [customMessage, setCustomMessage] = useState('');

  return <>
    <header>
      <Logo />
      <HeaderMenu />
    </header>

    <div>
       <h1 className="text-center mt-5">Please Wait! Fetching vehicle rates...</h1>
    </div>

    <Modal show={showError} onHide={handleErrorClose}>
        <Modal.Body className="text-center">
        <Modal.Title className="custom-modal-title text-danger">
            SORRY!
        </Modal.Title>
        <p className="text-center fs-14">
            {customError}
        </p>
        {/* <button className="booking-btn-conform btn btn-danger" onClick={handleErrorClose}>
            OK
        </button> */}
          <Link to="/" className="booking-btn-conform btn btn-danger">
            OK
          </Link>
        </Modal.Body>
    </Modal>
  </>;
};

export default QuotePage;
