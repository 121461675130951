import React from "react";

const VehicleInput = ({ icon, placeholder, type, pay, setPayment, paymentType }) => {
  let inputField = '';
  function handleFlight(e) {
    let timer = setTimeout(() => {
      if(type === 'name')
      {
        localStorage.setItem('flight_name', e.target.value);
        // console.log(type);
      } 
      else
      {
        localStorage.setItem('flight_number', e.target.value);
        // console.log(type);
      }
    }, 2500);
  }

  function handlePayment(e) {
    if(paymentType === 'number')
    {
      setPayment({ ...pay, cardNumber: e.target.value});
    }
    else if(paymentType === 'expire')
    {
      setPayment({ ...pay, expireDate: e.target.value});
    }
    else if(paymentType === 'cvc')
    {
      setPayment({ ...pay, cvc: e.target.value});
    }
    else if(paymentType === 'holder')
    {
      setPayment({ ...pay, holderName: e.target.value})
    }
    // switch(paymentType) {
    //   case 'number': 
    //     setPayment({ ...pay.cardNumber = e.target.value});
    //   case 'expire': 
    //     setPayment({ ...pay, expireDate: e.target.value});
    //   case 'cvc': 
    //     setPayment({ ...pay, cvc: e.target.value});
    //   case 'holder': 
    //     setPayment({ ...pay, holderName: e.target.value})
    // }
  }

  if(type === 'payment')
  {
    inputField = <input
    type="text"
    className="form-control"
    placeholder={placeholder} 
    onBlur={e => {handlePayment(e)}}
  />
  } 
  else
  {
    inputField = <input
    type="text"
    className="form-control"
    placeholder={placeholder} 
    onChange={e => {handleFlight(e)}}
  />
  }

  return (
    <>
      <div className="input-group ">
        <div className="input-group-prepend">
          <span className="input-group-text">
            <i className={"fa " + icon}></i>
          </span>
        </div>
        {inputField}
      </div>
    </>
  );
};

export default VehicleInput;
